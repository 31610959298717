// assets/js/app.js
require('../css/app.scss');
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css'

import 'jquery-datetimepicker/build/jquery.datetimepicker.full'
import 'jquery-datetimepicker/build/jquery.datetimepicker.min.css'

import 'select2'
import 'select2/dist/css/select2.css'

import 'pg-calendar'
import 'pg-calendar/dist/css/pignose.calendar.min.css'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

import * as Klaro from 'klaro/dist/klaro-no-css';

import 'bootstrap4-toggle'
import 'bootstrap4-toggle/css/bootstrap4-toggle.min.css'

AOS.init();

$( document ).ready(function() {
    $('.datepicker').datetimepicker({
        datepicker:true,
        timepicker:false,
        format:'d/m/Y',
    });
    $.datetimepicker.setLocale('fr');

    $(window).on('load', function () {
        if($('.owl-carousel.owl-carousel-categories').length > 0) {
            $('.owl-carousel.owl-carousel-categories').owlCarousel({
                loop:true,
                margin:10,
                nav:true,
                responsive:{
                    0:{
                        items:1
                    },
                    768:{
                        items:2
                    },
                    992:{
                        items:3
                    },
                    1200:{
                        items:4
                    }
                },
                navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>']
            })
        }

        if($('.owl-carousel.owl-carousel-mobile').length > 0) {
            $('.owl-carousel.owl-carousel-mobile').owlCarousel({
                loop:true,
                margin:0,
                nav:false,
                items:1
            })
        }
    })

    let carte = $('#carte-france');
    if(carte.length > 0) {
        carte.find('path.active').mousemove(function (e) {
            if($(this).attr('data-session') > 1) {
                $('.carte-tooltip').html('<b>'+$(this).attr('title')+'</b><br>'+$(this).attr('data-session')+' formations disponibles')
            } else {
                $('.carte-tooltip').html('<b>'+$(this).attr('title')+'</b><br>'+$(this).attr('data-session')+' formation disponible')
            }
            $('.carte-tooltip')
                .css({'top': e.clientY + 10, 'left' : e.clientX + 10})
                .addClass('active')

        })

        $(this).mouseout(function () {
            $('.carte-tooltip')
                .html('')
                .css({'top': 0, 'left' : 0})
                .removeClass('active')
        })
    }

    let carte2 = $('#carte-france-tunnel');
    if(carte2.length > 0) {
        carte2.find('path').mousemove(function (e) {
            $('.carte-tooltip-tunnel').html('<b>'+$(this).attr('title')+'</b>');
            $('.carte-tooltip-tunnel')
                .css({'top': e.clientY + 10, 'left' : e.clientX + 10})
                .addClass('active')

        })

        carte2.find('path').click(function (e) {
            $(this).toggleClass('active');
        });

        $(this).mouseout(function () {
            $('.carte-tooltip-tunnel')
                .html('')
                .css({'top': 0, 'left' : 0})
                .removeClass('active')
        })
    }

    let menuFixed = $('.menu-fixed');

    if(menuFixed.length > 0 && $('main.tunnel-formation').length == 0) {
        let menuFixedSize = menuFixed.height();

        $(window).scroll(function (e) {
            let bodyHeight = $('body').height();
            if(bodyHeight - (menuFixedSize*2) > $(window).height()) {
                let scroll = $(window).scrollTop();
                if(scroll > menuFixedSize) {
                    menuFixed.addClass('fixed')
                } else {
                    menuFixed.removeClass('fixed')
                }
            }
        })
    }

    let showChar = 300;
    let ellipsestext = "...";

    $(".truncate").each(function() {
        if($(this).data('limit')) {
            showChar = $(this).data('limit');
        }
        let content = $(this).html();
        if (content.length > showChar) {
            let c = content.substr(0, showChar);
            let h = content;
            let html =
                '<div class="truncate-text" style="display:block">' +
                c +
                '<span class="moreellipses">' +
                ellipsestext +
                '&nbsp;&nbsp;<a href="" class="moreless more">lire plus</a></span></span></div><div class="truncate-text" style="display:none">' +
                h +
                '<a href="" class="moreless less">lire moins</a></span></div>';

            $(this).html(html);
        }
    });

    $(".moreless").click(function() {
        let thisEl = $(this);
        let cT = thisEl.closest(".truncate-text");
        let tX = ".truncate-text";

        if (thisEl.hasClass("less")) {
            cT.prev(tX).toggle();
            cT.slideToggle();
        } else {
            cT.toggle();
            cT.next(tX).fadeToggle();
        }
        return false;
    });
    /* end iffe */

    $('.more-filter-btn').click(function () {
        $('.more-filter').toggleClass('active')
        if($('.more-filter').hasClass('active')) {
            $('.more-filter-btn span').html('Moins de filtres')
            $(this).closest('.container-bg-search').addClass('pb-5');
        } else {
            $('.more-filter-btn span').html('Plus de filtres')
            $(this).closest('.container-bg-search').removeClass('pb-5');
        }
    });


    const config = {
        htmlTexts: true,
        acceptAll: true,
        translations: {
            fr: {
                decline: " ",
                save: "Sauvegarder",
                ok: "J'ai compris",
                acceptAll: "J'ai compris",
                acceptSelected: "Sauvegarder mes choix",
                close: "Fermer",
                consentNotice: {
                    changeDescription: "There were changes since your last visit, please renew your consent.",
                    description: "Bienvenue sur le site A2FM<br><br><strong class='mb-0'>OPTIMISATION DE VOTRE EXPÉRIENCE</strong><br> " +
                        'Des cookies et autres technologies similaires sont déposés sur notre site afin de vous proposer une utilisation la plus optimisée possible. Ceux-ci sont notamment utiles lors de la gestion de vos sessions de connexion.' +
                        "<br><br>Vous pouvez consentir ou refuser les cookies à partir des boutons ci-dessous.<br><br>" +
                        "Vous disposez de la possibilité de retirer votre consentement à tout moment en remplissant le formulaire disponible sur le lien « Demande RGPD » en bas de page de notre site Internet.<br>Retrouvez également toutes les informations relatives à cette politique ainsi que les finalités et données collectées via ces cookies dans le lien « CGU » en bas de page de notre site Internet.",
                    learnMore: "Gérer les cookies",
                    testing: "Testing mode!"
                },

                consentModal: {
                    title: 'Gestion de vos préférences sur les cookies',
                    description: "Certaines fonctionnalités de ce site sont nécessaires pour le bon fonctionnement de l'application. Ces fonctionnalités déposent des cookies permettant notamment de stocker vos préférences et maintenir la session lors de vos connexions de compte. Vous pouvez vous informer sur la nature des cookies déposés, les accepter ou les refuser.<br><br>" +
                        "Les services suivant sont essentiels au bon fonctionnement de ce site Web, détail sur le lien « CGU » en bas de page de notre site Internet.<br><br>"
                },
            }
        },
        purposes: {
            advertising: {
                description: "These services process personal information to show you personalized or interest-based advertisements.",
                title: "Advertising"
            },
            functional: {
                description: "These services are essential for the correct functioning of this website. You cannot disable them here as the service would not work correctly otherwise.\n",
                title: "Service Provision"
            },
            marketing: {
                description: "Ces cookies nous permettent de réaliser des études et des statistiques concernant ce que vous visualisez ou concernant vos interactions avec les contenus et les services du site. L'objectif est de mesurer la performance des contenus et des services du site pour, ensuite, nous aider à améliorer votre expérience, les contenus que nous vous montrons, ainsi que l'ergonomie générale du site.",
                title: "Marketing"
            },
            performance: {
                description: "Ce site utilise des cookies nécessaires à son bon fonctionnement.\n",
                title: "API"
            },
        },

        services: [
            {
                name: 'EU_COOKIE_LAW',
                purposes: ['EU_COOKIE_LAW'],
                onAccept: ``,
                onDecline: ``,
                required: true,
                contextualConsentOnly: true,
            },
            {
                name: 'PHPSESSID',
                purposes: ['PHPSESSID'],
                onAccept: ``,
                onDecline: ``,
                required: true,
                contextualConsentOnly: true,
            }
        ]

    };

    // we assign the Klaro module to the window, so that we can access it in JS
    window.klaro = Klaro;
    window.klaroConfig = config;

    Klaro.setup(config);
    klaro.language("fr")

    //klaro.show()

    $('.select2').select2();

    if($('main.tunnel-formation').length > 0) {
        window.scrollTo({top: 0, behavior: 'smooth'});

        $('.tunnel-formation').css({'min-height' : 'calc(100vh - '+($('.a2fm-main-nav-mobile').outerHeight(true)+$('.a2fm-main-nav-bg').outerHeight(true)+$('.a2fm-main-footer').outerHeight(true))+'px)'})

        $('.checkox-button').click(function () {
            let input = $(this).find('input');

            if(input.prop("checked")) {

                if($(input).is(':checkbox')) {
                    input.prop("checked", false);
                }

                if(input.attr('name') == "regions" && input.val() == "-1") {
                    $('#carte-france-tunnel path').removeClass('active');
                }
            } else {
                input.prop("checked", true);

                if(input.attr('name') == "regions" && input.val() == "-1") {
                    $('#carte-france-tunnel path').addClass('active');
                }
            }

            checkActiveForNext().each(function () {
                if($(this).attr('type') == "radio") {
                    $('.formation-finder-actions-top .btn-action-step.btn-next-step').click()
                }
            })
        });

        let filtresFormations = []
        let cptPoints = 1;
        let maxPoints = 6;

        $('.formation-finder-actions .btn-action-step').click(function (e) {
            if(!$(this).hasClass('inactive')) {
                let currentStep = parseInt($(".formation-finder-content li.active").data('step'));
                let nextStep = 0;

                if($(this).data('action') == "prev") {
                    nextStep = parseInt(currentStep-1);
                    cptPoints -=1;
                }
                if($(this).data('action') == "next") {
                    nextStep = parseInt(currentStep+1);
                    cptPoints +=1;
                }

                if(nextStep > -1 && $(".formation-finder-content li[data-step='"+nextStep+"']").length > 0) {
                    window.scrollTo({top: 0, behavior: 'smooth'});

                    if(nextStep > currentStep) {

                        if(currentStep == 2) {

                            $('#carte-france-tunnel path.land.active')

                            let values = [];
                            let key = "regions";
                            $('#carte-france-tunnel path.land.active').each(function () {
                                values.push($(this).attr('title'));
                            });

                            filtresFormations[key] = values;

                        } else if(currentStep == 4) {
                            let values = [];
                            let key = "";
                            $('.formation-finder-content li[data-step="'+currentStep+'"] .date-input-container input').each(function () {
                                key = $(this).attr('name');
                                values.push($(this).val());
                            });

                            if(key != "") {
                                filtresFormations[key] = values;
                            }

                        } else {
                            let values = [];
                            let key = "";
                            $('.formation-finder-content li[data-step="'+currentStep+'"] .checkox-button input:checked').each(function () {
                                key = $(this).attr('name');
                                values.push($(this).val());
                            });

                            if(key != "") {
                                filtresFormations[key] = values;
                            }
                        }

                        $('.progress-points').append(' <span class="point">'+(cptPoints)+'</span>');

                        if(currentStep == 1 && filtresFormations['presence'] != null && filtresFormations['presence'].length == 1 && filtresFormations['presence'][0] == "ligne") {
                            nextStep +=1;
                            maxPoints = 5;
                            $('.progress-timeline').attr('data-points', maxPoints);
                        }

                        if(nextStep == parseInt($('.formation-finder-actions-top').data('max'))-1) {
                            $('#form_formations_personnalisees').submit();
                        }

                    } else {
                        $('.progress-points .point').last().remove();

                        if(currentStep == 3 && filtresFormations['presence'] != null && filtresFormations['presence'].length == 1 && filtresFormations['presence'][0] == "ligne") {
                            nextStep -=1;
                            maxPoints = 6;
                            $('.progress-timeline').attr('data-points', maxPoints);
                        }
                    }

                    $(".formation-finder-content li").removeClass('active');
                    $(".formation-finder-content li[data-step='"+nextStep+"']").addClass('active');

                    $('.progress-progression').css({'width': 'calc(calc(100% / '+maxPoints+') * '+$('.progress-points .point').length+')' });


                    if(nextStep == 0) {
                        $('.formation-finder-actions-top .btn-action-step.btn-prev-step').addClass('d-none');
                        $('.formation-finder-actions-top .btn-action-step.btn-next-step').addClass('d-none');
                    } else if(nextStep == parseInt($('.formation-finder-actions-top').data('max') - 2)) {
                        $('.formation-finder-actions-top .btn-action-step.btn-next-step .text-button').html('Rechercher');
                        $('.formation-finder-actions-top .btn-action-step.btn-next-step').removeClass('d-none');
                    } else if(nextStep == parseInt($('.formation-finder-actions-top').data('max') - 1)) {
                        $('.formation-finder-actions-top .btn-action-step.btn-next-step').addClass('d-none');
                    } else {
                        $('.formation-finder-actions-top .btn-action-step').removeClass('d-none');
                        $('.formation-finder-actions-top .btn-action-step.btn-next-step .text-button').html('Suivant');
                    }

                    if(nextStep > 0 && nextStep < parseInt($('.formation-finder-actions-top').data('max') - 1)) {
                        checkActiveForNext()
                    }

                    if(nextStep > 0) {
                        $('.formation-finder-actions-bottom .btn-action-step').addClass('d-none');
                    } else {
                        $('.formation-finder-actions-bottom .btn-action-step').removeClass('d-none');
                    }

                }
            }
        });

        $('#form_formations_personnalisees').submit(function (e) {
            e.stopPropagation();
            e.preventDefault();

            $('.img-result').removeClass('active');
            $('.container-formations').html('');
            $(".a2fm-header-content-title-end .fa.fa-spinner").addClass('active');
            $('.formation-finder-actions-top').addClass('d-none');

            let datas = $(this).serializeArray();


            let regions = datas.find(x => x.name === 'regions');
            if(regions == null) {
                if(filtresFormations['regions'] != null && filtresFormations['regions'].length > 0) {
                    $(filtresFormations['regions']).each(function (index, value) {
                        datas.push({'name' :'regions[]', 'value' : value})
                    });

                } else {
                    datas.push({'name' :'regions', 'value' : '-1'})
                }
            }

            $.ajax({
                url: $(this).data('action'),
                method: "POST",
                dataType : "json",
                data : datas
            })
                .done(function(response){
                    $(".a2fm-header-content-title-end .fa.fa-spinner").removeClass('active');
                    $('.formation-finder-actions-top').removeClass('d-none');

                    $('.img-result').addClass('active');
                    $('.container-formations').html(response.content);
                    $('.container-formations').removeClass('d-none');
                })
                .fail(function(error){
                    console.log(error);
                    alert("Une erreur est survenue lors de la recherche. Merci de recommencer, si le problème persiste, veuillez contacter un responsable.");
                })
        });

        $('#carte-france-tunnel path').click(function () {
            checkActiveForNext();
        });

        const picker = new easepick.create({
            element: "#checkin",
            css: [
                "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css",
                $('#checkin').data('css')
            ],
            zIndex: 10,
            lang: "fr-FR",
            format: "DD/MM/YYYY",
            grid: 2,
            calendars: 2,
            inline: true,
            RangePlugin: {
                elementEnd: "#checkin-end",
                repick: true,
                strict: false,
                locale: {
                    one: "jour",
                    other: "jours"
                }
            },
            LockPlugin: {
                minDate: $('#checkin').data('min')
            },
            plugins: [
                "RangePlugin",
                "LockPlugin"
            ],
            setup(picker) {
                picker.on('select', (e) => {
                    const { start, end } = e.detail;
                    checkActiveForNext();
                });
            },
        })


    }

    function checkActiveForNext() {
        let currentStep = parseInt($(".formation-finder-content li.active").data('step'));
        let title = "Sélectionnez une option pour continuer";
        let toggle = "inactive";
        let actives = [];

        if(currentStep == 2) {
            actives = $('#carte-france-tunnel path.land.active');
        } else if(currentStep == 4) {
            $('.formation-finder-content li[data-step="'+currentStep+'"] .date-input-container input').each(function () {
                if($(this).val() != "") {
                    actives.push($(this).val())
                } else {
                    actives = [];
                    return false;
                }
            });
        } else {
            actives = $('.formation-finder-content li[data-step="'+currentStep+'"] .checkox-button input:checked');
        }

        if(actives.length > 0) {
            title = "";
            $('.formation-finder-actions-top .btn-action-step.btn-next-step').removeClass(toggle);
        } else {
            $('.formation-finder-actions-top .btn-action-step.btn-next-step').addClass(toggle);
        }

        $('.formation-finder-actions-top .btn-action-step.btn-next-step').attr('data-original-title', title);
        $('.formation-finder-actions-top .btn-action-step.btn-next-step').blur();

        return actives;
    }


    if($('#mosaique_formations_a2fm').length > 0) {

        if(getUrlParameter('categorie') && $('.a2fm-liste-formations-mosaique').length > 0) {
            document.querySelector('.a2fm-liste-formations-mosaique').scrollIntoView({
                behavior: 'smooth'
            });
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }


        $('.checkox-button').click(function () {
            let input = $(this).find('input');

            if (input.prop("checked")) {
                if ($(input).is(':checkbox')) {
                    input.prop("checked", false);
                }
            } else {
                input.prop("checked", true);
                $('.loader').removeClass('d-none');

                if($(this).find('input[name=categorie]').length > 0) {
                    $('input[name=theme]').prop("checked", false)
                    $('input[name=theme][value="-1"]').prop("checked", true);
                }

                $($(this).parents('form')[0]).submit();

            }
        });
    }

    function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };

    if($('#sessionsFiltered').length > 0) {

        $('#sessionsFilteredButtons').on( 'click', 'a', function() {
            var filterValue = $( this ).attr('data-filter');
            if(filterValue == "*") {
                $('#sessionsFiltered .sessionsFilteredItem').removeClass('d-none');
            } else {
                $('#sessionsFiltered .sessionsFilteredItem').each(function () {
                    if($(this).hasClass(filterValue)) {
                        $(this).removeClass('d-none');
                    } else {
                        $(this).addClass('d-none');
                    }
                })
            }
            $('#sessionsFiltered .sessionsFilteredMonth').each(function () {
                if($('#sessionsFiltered .sessionsFilteredItem[data-month="'+$(this).data('month')+'"]:not(.d-none)').length == 0) {
                    $(this).addClass('d-none');
                } else {
                    $(this).removeClass('d-none');
                }
            })

        });
    }
});

const imagesContext = require.context('../img', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);
